import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import * as styles from "../components/index.module.css"

const IndexPage = () => (
    <Layout>
        <Seo title='Home' />
        <div className='content-wrapper'>
            <StaticImage
                src="../images/Synodic-white-background-1280.webp"
                loading="eager"
                placeholder="none"
                backgroundColor="#FFF"
                width={600}
                quality={100}
                alt=""
                style={{ marginBottom: `var(--space-3)` }}
            />
            <h1>
                Blazingly Fast Software
            </h1>
            <br />
            <br />
            <Link
                to='/contact'
                className='link-button'
            >
                GET IN TOUCH
            </Link>
        </div>
    </Layout>
)


export default IndexPage